import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import AnonymousLayout from 'Components/anonymous-layout/anonymous-layout';
import AuthenticatedLayoutMobile from 'Components/authenticated-layout-mobile';
import AuthenticatedLayout from 'Components/authenticated-layout/authenticated-layout';
import ValidatedRoute from 'Components/validated-route/validated-route';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    CLUBS_URL,
    COWORKERS_URL,
    COWORKER_TIMESHEET_URL,
    DASHBOARD_URL,
    EVENTS_URL,
    GLOBAL_LANGUAGE_URL,
    HAPPENINGS_URL,
    MOBILE_MAX,
    OFFICES_URL,
    ONBOARDING_URL,
    PLAN_SCHEDULE_URL,
    POLICIES_URL,
    PROFILE_URL,
    SETTINGS_URL,
    SLACK_INTEGRATION_URL,
    STATISTICS_REAL_ESTATE_URL,
    TEAMS_URL,
    USERS_URL,
} from 'Models/Constants';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Route, Switch, useLocation } from 'react-router-dom';
import NotFoundPage from './404-not-found';
import ChangePasswordCallback from './actions/change-password-callback';
import ConfirmAccount from './actions/confirm-account';
import ConfirmAccountCallback from './actions/confirm-account-callback';
import GoogleCalendarCallback from './actions/google-calendar-callback';
import ResetPasswordCallback from './actions/reset-password-callback';
import SignInCallback from './actions/signin-callback';
import SignInFailure from './actions/signin-failure';
import Clubs from './clubs';
import ClubDetails from './clubs/id';
import Coworkers from './coworkers';
import CoworkerTimeSheet from './coworkers/time-sheet';
import Dashboard from './dashboard';
import DashboardMobile from './dashboard-mobile';
import EventDetails from './events/id';
import Events from './events/index';
import Login from './login';
import TeamManagement from './team-management';
import TeamManagementDetail from './team-management/id';
import TeamManagementCoworkerDetail from './team-management/id/coworker-details';
import Onboarding from './onboarding';
import PlanSchedule from './plan-schedule/plan-schedule';
import Profile from './profile';
import RealEstateStatistics from './reports/real-estate';
import Settings from './settings';
import SettingsHappenings from './settings/happenings';
import HappeningDetails from './settings/happenings/id';
import Offices from './settings/offices';
import OfficesDetails from './settings/offices/id';
import Policies from './settings/policies';
import PolicyDetails from './settings/policies/id';
import SlackIntegration from './settings/slack-integration';
import Teams from './settings/teams';
import TeamDetails, { TeamDetailsContext } from './settings/teams/id';
import Users from './settings/users';
import GlobalLanguage from './settings/global-language';
import NavigationBlocker from 'Components/navigation-blocker';
import UserDetails, { UserDetailsContext } from './settings/users/id';

const Routes: React.FunctionComponent = observer(() => {
    const location = useLocation();
    const { requestHistoryStore } = useStores();
    const managerAdminPermission = [ManagementRoleDto.Manager, ManagementRoleDto.Administrator];
    const adminPermission = [ManagementRoleDto.Administrator];
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX });

    useEffect(() => {
        requestHistoryStore.setPreviousUrl(location.pathname);
    }, [location]);

    return (
        <Switch>
            {/* Anonymous routes */}
            <Route exact path="/actions/confirm-account-callback">
                <AnonymousLayout>
                    <ConfirmAccountCallback />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/confirm-account">
                <AnonymousLayout>
                    <ConfirmAccount />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/reset-password-callback">
                <AnonymousLayout>
                    <ResetPasswordCallback />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/signin-callback">
                <AnonymousLayout>
                    <SignInCallback />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/signin-failure">
                <AnonymousLayout>
                    <SignInFailure />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/google-calendar-callback">
                <AnonymousLayout>
                    <GoogleCalendarCallback />
                </AnonymousLayout>
            </Route>

            <Route exact path="/">
                <AnonymousLayout>
                    <Login />
                </AnonymousLayout>
            </Route>
            {/* -- END Anonymous routes */}

            {/* Authenticated routes */}

            <ValidatedRoute exact path={PROFILE_URL}>
                <AuthenticatedLayout>
                    <NavigationBlocker>
                        <Profile />
                    </NavigationBlocker>
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={DASHBOARD_URL}>
                {isMobile ? (
                    <AuthenticatedLayoutMobile>
                        <DashboardMobile />
                    </AuthenticatedLayoutMobile>
                ) : (
                    <AuthenticatedLayout>
                        <Dashboard />
                    </AuthenticatedLayout>
                )}
            </ValidatedRoute>

            <ValidatedRoute exact path={COWORKERS_URL}>
                <AuthenticatedLayout>
                    <Coworkers />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={COWORKERS_URL + COWORKER_TIMESHEET_URL}>
                <AuthenticatedLayout>
                    <CoworkerTimeSheet />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={COWORKERS_URL + '/:id'}>
                <AuthenticatedLayout>
                    <Coworkers />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={CLUBS_URL}>
                <AuthenticatedLayout>
                    <Clubs />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={CLUBS_URL + '/:id'}>
                <AuthenticatedLayout>
                    <ClubDetails />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={PLAN_SCHEDULE_URL}>
                <AuthenticatedLayout>
                    <PlanSchedule />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={EVENTS_URL}>
                <AuthenticatedLayout>
                    <Events />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={EVENTS_URL + '/:id'}>
                <AuthenticatedLayout>
                    <EventDetails />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={TEAMS_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={managerAdminPermission}>
                    <TeamManagement />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={TEAMS_URL + '/:id'}>
                <AuthenticatedLayout onlyAuthorizedRoles={managerAdminPermission}>
                    <TeamManagementDetail />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={TEAMS_URL + '/:teamId' + USERS_URL + '/:id'}>
                <AuthenticatedLayout onlyAuthorizedRoles={managerAdminPermission}>
                    <TeamManagementCoworkerDetail />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <Settings />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL + USERS_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <Users />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute path={SETTINGS_URL + USERS_URL + '/:id'}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <UserDetails context={UserDetailsContext.Settings} />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL + TEAMS_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <Teams />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL + TEAMS_URL + '/:id'}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <TeamDetails context={TeamDetailsContext.Settings} />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL + OFFICES_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <Offices />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL + OFFICES_URL + '/:id'}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <OfficesDetails />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL + POLICIES_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <Policies />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute path={SETTINGS_URL + POLICIES_URL + '/:id'}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <PolicyDetails />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SETTINGS_URL + HAPPENINGS_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <SettingsHappenings />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute path={SETTINGS_URL + HAPPENINGS_URL + '/:id'}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <HappeningDetails />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute path={SETTINGS_URL + SLACK_INTEGRATION_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <SlackIntegration />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute path={SETTINGS_URL + GLOBAL_LANGUAGE_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={adminPermission}>
                    <GlobalLanguage />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute path={STATISTICS_REAL_ESTATE_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={managerAdminPermission}>
                    <RealEstateStatistics />
                </AuthenticatedLayout>
            </ValidatedRoute>

            {/* <ValidatedRoute path={STATISTICS_HUMAN_RESOURCES_URL}>
                <AuthenticatedLayout onlyAuthorizedRoles={managerAdminPermission}>
                    <HumanResourcesStatistics />
                </AuthenticatedLayout>
            </ValidatedRoute> */}

            <ValidatedRoute path={ONBOARDING_URL}>
                <Onboarding />
            </ValidatedRoute>

            <ValidatedRoute exact path="/actions/change-password-callback">
                <AuthenticatedLayout>
                    <ChangePasswordCallback />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute>
                {/* must be last route*/}
                <NotFoundPage />
            </ValidatedRoute>

            {/* -- END Authenticated routes */}
        </Switch>
    );
});

export default Routes;
