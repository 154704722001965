import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import './space-viewer-card.less';
import SpaceViewerTag, { SpaceViewerTagType } from './space-viewer-tag';
import SpaceViewerDesk, { ImgPosition, SpaceViewerDeskType } from './space-viewer-desk';
import Skeleton from 'Components/skeleton';
import { spaceSkeletonShape } from './space-viewer-skeleton-shapes';
import { OfficeSpaceOccupantDto } from 'Api/Features/Offices/Dtos/OfficeSpaceOccupantDto';
import { QueuedFetch } from 'Hooks/use-fetch-queue';
import { useService, useStores } from 'Hooks';
import { OfficeService } from 'Services/OfficeService';
import { observer } from 'mobx-react';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE_INFINITE_LOADING } from 'Models/Constants';
import { SpaceViewerScheduleType } from './space-viewer-modal-content';
import { GetOfficeSpaceOccupantsRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupantsRequestDto';

interface SpaceViewerCardProps {
    space: OfficeSpaceDto;
    isSelected: boolean;
    onSelectSpace: (space: SingleSelectCustomOption) => void;
    addToFetchQueue: (fetchParams: QueuedFetch) => void;
    timeRange: {
        startTime: string;
        endTime: string;
    };
    scheduleType: SpaceViewerScheduleType;
}

export enum SpaceViewerCardWidth {
    small = 'small',
    mid = 'mid',
    large = 'large',
    xLarge = 'xLarge',
}

const DEFAULT_CARD_HEIGHT = 197;
const FULL_GROUP_ROW_HEIGHT = 91;
const HALF_GROUP_ROW_HEIGHT = 63;

interface Desk {
    occupant?: OfficeSpaceOccupantDto;
    type: SpaceViewerDeskType;
}

const SpaceViewerCard: React.FC<SpaceViewerCardProps> = observer(
    ({ space, isSelected, onSelectSpace, addToFetchQueue, timeRange, scheduleType }) => {
        const { t } = useTranslation();
        const officeService = useService(OfficeService);
        const { userStore, languageStore } = useStores();
        const [desks, setDesks] = useState<Desk[]>([]);
        const [cardWidth, setCardWidth] = useState<SpaceViewerCardWidth>(
            SpaceViewerCardWidth.small
        );
        const [deskMaxColumns, setDeskMaxColumns] = useState(0);
        const [isFullCapacity, setIsFullCapacity] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [cardIsLoaded, setCardIsLoaded] = useState(false);
        const [occupants, setOccupants] = useState<OfficeSpaceOccupantDto[] | undefined>(undefined);

        const ref = useRef(null);

        useEffect(() => {
            //Trigger fetching the space people only when space is in view
            const observer = new IntersectionObserver((entries) => {
                const [entry] = entries;
                if (entry.isIntersecting && occupants === undefined && isLoading === false) {
                    setIsLoading(true);
                    onFetchSpaceOccupants(space.id ?? '', 0, []);
                }
            });

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        }, [occupants, isLoading]);

        useEffect(() => {
            //set card dimensions with space capacity
            if (space.capacity) {
                //https://www.figma.com/file/DzlPyFRRKhf12ehAdxBleX/Flexy-2.0?type=design&node-id=14445-250813&t=21dp0fZ2zEmYbuFx-4
                if (space.capacity <= 6) {
                    setCardWidth(SpaceViewerCardWidth.small);
                    setDeskMaxColumns(space.capacity <= 4 ? 2 : space.capacity / 2);
                } else if (space.capacity <= 10) {
                    setCardWidth(SpaceViewerCardWidth.mid);
                    setDeskMaxColumns(5);
                } else if (space.capacity <= 14) {
                    setCardWidth(SpaceViewerCardWidth.large);
                    setDeskMaxColumns(7);
                } else {
                    setCardWidth(SpaceViewerCardWidth.xLarge);
                    setDeskMaxColumns(12);
                }
            }
        }, [space.capacity]);

        useEffect(() => {
            //set the desks with the occupants
            let userIsInSpace = false;
            if (occupants !== undefined && space.capacity) {
                const desks: Desk[] = [];

                //as of sp22 it is possible to have more people than capacity. Ignore overflow
                for (let index = 0; index < occupants.length && index < space.capacity; index++) {
                    let type: SpaceViewerDeskType = SpaceViewerDeskType.occupied;
                    if (
                        userStore.userInfo?.team?.id !== undefined &&
                        userStore.userInfo.team.id === occupants[index].team?.id
                    )
                        type = SpaceViewerDeskType.teamMember;

                    if (occupants[index].favoriteInfo?.id) type = SpaceViewerDeskType.favourite;

                    if (occupants[index].id === userStore.userInfo?.id) {
                        userIsInSpace = true;
                        type = SpaceViewerDeskType.currentUser;
                    }

                    desks.push({ occupant: occupants[index], type });
                }

                //the remaining empty desks
                for (let index = 0; index < space.capacity - occupants.length; index++) {
                    desks.push({ type: SpaceViewerDeskType.empty });
                }
                setDesks(desks);

                setIsLoading(false);
                setCardIsLoaded(true);
                setIsFullCapacity(userIsInSpace ? false : occupants.length >= space.capacity);
            }
        }, [occupants, space.capacity, userStore.userInfo?.team]);

        const occupantsFetchCallback = (
            fetchId: string,
            hasError: boolean,
            response: [OfficeSpaceOccupantDto[], number],
            accumulatedFetchOccupants: OfficeSpaceOccupantDto[]
        ) => {
            if (!hasError) {
                const fetchIdSplit = fetchId.split('_'); //fetchId: spaceId + '_' + page
                const currentPage = parseInt(fetchIdSplit[1]);

                if (response[0].length + currentPage * PAGE_SIZE_INFINITE_LOADING < response[1]) {
                    onFetchSpaceOccupants(fetchIdSplit[0], currentPage + 1, [
                        ...accumulatedFetchOccupants,
                        ...response[0],
                    ]);
                } else {
                    setOccupants([...accumulatedFetchOccupants, ...response[0]]);
                }
            }
        };

        const onFetchSpaceOccupants = (
            spaceId: string,
            page: number,
            accumulatedResponse: OfficeSpaceOccupantDto[]
        ) => {
            const requestParams: GetOfficeSpaceOccupantsRequestDto = {
                officeSpaceId: spaceId,
                startTime: timeRange.startTime,
                endTime: timeRange.endTime,
                timeZone: userStore.userInfo?.timeZone,
                favoritesForUserId: userStore.userInfo?.id,
                pageSize: PAGE_SIZE_INFINITE_LOADING,
                page: page,
            };
            addToFetchQueue({
                fetchFunction: async () =>
                    scheduleType === SpaceViewerScheduleType.schedule
                        ? officeService.getOfficeSpaceOccupants(requestParams)
                        : officeService.getOfficeSpaceDefaultOccupants(requestParams),
                fetchId: spaceId + '_' + page,
                callback: occupantsFetchCallback,
                extraCallbackData: accumulatedResponse,
            });
        };

        const getCardHeightFromCapacity = (
            cardWidth: SpaceViewerCardWidth,
            capacity?: number
        ): number => {
            if (cardWidth !== SpaceViewerCardWidth.xLarge || !capacity) return DEFAULT_CARD_HEIGHT;
            else {
                const FULL_ROW = 24;
                const COLUMNS = 12;
                let fullRows = Math.floor(capacity / FULL_ROW); //xLarge cards have 2 groups of 12 columns to form a row.
                let HalfRowOfDesk = 0;

                const remainingDesks = capacity - fullRows * FULL_ROW;

                if (remainingDesks > 0 && remainingDesks <= COLUMNS) HalfRowOfDesk++;
                else if (remainingDesks > COLUMNS) fullRows++;

                return (
                    DEFAULT_CARD_HEIGHT +
                    /* we can ignore the first row its already considered in DEFAULT_CARD_HEIGHT*/
                    (fullRows - 1) * FULL_GROUP_ROW_HEIGHT +
                    (HalfRowOfDesk > 0 ? HALF_GROUP_ROW_HEIGHT : 0)
                );
            }
        };

        const loadingCardHeight = useMemo(
            () => getCardHeightFromCapacity(cardWidth, space.capacity),
            [space.capacity, cardWidth]
        );

        return (
            <div
                className={`SpaceViewerCard ${
                    isSelected && cardIsLoaded && 'selected'
                } ${cardWidth} ${isFullCapacity && 'full-cap'} ${isLoading && 'loading'} ${
                    cardIsLoaded && 'cursor-pointer'
                }`}
                onClick={() => {
                    cardIsLoaded && !isFullCapacity
                        ? onSelectSpace({
                              label: space.name ?? '',
                              value: space.id ?? '',
                              content: {
                                  node: (
                                      <div>
                                          {t('TimePeriodModal.occupancy_period')}:{' '}
                                          {occupants?.length}/{space.capacity}
                                      </div>
                                  ),
                                  className: 'text-caption-1',
                                  showSelected: true,
                              },
                          })
                        : undefined;
                }}
                ref={ref}
                key={'SpaceViewerCard' + space?.id}
            >
                <Skeleton
                    isLoaded={cardIsLoaded}
                    placeholder={spaceSkeletonShape(cardWidth, loadingCardHeight)}
                >
                    <>
                        <div className="space-info-container">
                            <div className="name">{space.name}</div>
                            <div className="capacity text-caption-2-bold">
                                {occupants?.length}/{space.capacity}
                            </div>
                        </div>

                        <div
                            className={`desks-container ${
                                (space.capacity ?? 0) <= 4 ? 'four-desks' : ''
                            }`}
                        >
                            {desks.map((desk, i, arr) => {
                                const isXLargeCard = cardWidth === SpaceViewerCardWidth.xLarge;
                                const isEvenRow = (Math.floor(i / deskMaxColumns) + 1) % 2 === 0;
                                const isLastDeskInRow = (i + 1) % deskMaxColumns === 0;
                                const isNotLastDeskInCard = arr.length - 1 !== i;

                                if (
                                    isXLargeCard &&
                                    isEvenRow &&
                                    isNotLastDeskInCard &&
                                    isLastDeskInRow
                                )
                                    return (
                                        <div key={'desk' + space.id + i}>
                                            <SpaceViewerDesk
                                                type={desk.type}
                                                imgPosition={
                                                    /*after deskMaxColumns, switch the position of the image from top to bottom*/
                                                    Math.floor(i / deskMaxColumns) % 2
                                                        ? ImgPosition.bottom
                                                        : ImgPosition.top
                                                }
                                                imgUrl={desk.occupant?.imageUrl}
                                                tooltipId={'tooltip' + space.id + i}
                                                occupant={desk.occupant}
                                                timeRange={timeRange}
                                                timezone={userStore.userInfo?.timeZone}
                                                spaceId={space.id}
                                                language={languageStore.currentLanguage}
                                                scheduleType={scheduleType}
                                            />
                                            <div key={'spacer' + space.id + 1} className="spacer" />
                                        </div>
                                    );
                                else
                                    return (
                                        <div key={'desk' + space.id + i}>
                                            <SpaceViewerDesk
                                                type={desk.type}
                                                imgPosition={
                                                    Math.floor(i / deskMaxColumns) % 2
                                                        ? ImgPosition.bottom
                                                        : ImgPosition.top
                                                }
                                                imgUrl={desk.occupant?.imageUrl}
                                                tooltipId={'tooltip' + space.id + i}
                                                occupant={desk.occupant}
                                                timeRange={timeRange}
                                                timezone={userStore.userInfo?.timeZone}
                                                spaceId={space.id}
                                                language={languageStore.currentLanguage}
                                                scheduleType={scheduleType}
                                            />
                                        </div>
                                    );
                            })}
                        </div>

                        <div className="tag">
                            <SpaceViewerTag
                                type={
                                    isSelected
                                        ? SpaceViewerTagType.selected
                                        : isFullCapacity
                                        ? SpaceViewerTagType.unavailable
                                        : SpaceViewerTagType.available
                                }
                            />
                        </div>
                    </>
                </Skeleton>
            </div>
        );
    }
);
export default SpaceViewerCard;
