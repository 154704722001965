import { CreateUserScheduleEventRequestDto } from 'Api/Features/Schedules/Dtos/CreateUserScheduleEventRequestDto';
import { GetDailyWorkStatsRequestDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsRequestDto';
import { GetDailyWorkStatsResponseDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsResponseDto';
import { GetDaySchedulesRequestDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesRequestDto';
import { GetDaySchedulesResponseDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesResponseDto';
import { GetDaySchedulesResponseItemDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesResponseItemDto';
import { GetDaySimilarSchedulesRequestDto } from 'Api/Features/Schedules/Dtos/GetDaySimilarSchedulesRequestDto';
import { GetDaySimilarSchedulesResponseDto } from 'Api/Features/Schedules/Dtos/GetDaySimilarSchedulesResponseDto';
import { GetDaySimilarSchedulesResponseItemDto } from 'Api/Features/Schedules/Dtos/GetDaySimilarSchedulesResponseItemDto';
import { GetDayUserCountsRequestDto } from 'Api/Features/Schedules/Dtos/GetDayUserCountsRequestDto';
import { GetDayUserCountsResponseDto } from 'Api/Features/Schedules/Dtos/GetDayUserCountsResponseDto';
import { GetUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserDefaultScheduleRequestDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { GetUserWorkStatusRequestDto } from 'Api/Features/Schedules/Dtos/GetUserWorkStatusRequestDto';
import { GetUserWorkStatusResponseDto } from 'Api/Features/Schedules/Dtos/GetUserWorkStatusResponseDto';
import { ScheduleDto } from 'Api/Features/Schedules/Dtos/ScheduleDto';
import { UpdateUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserDefaultScheduleRequestDto';
import { UpdateUserScheduleEventRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserScheduleEventRequestDto';
import { UpdateUserScheduleTimelineRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserScheduleTimelineRequestDto';
import { UpdateUserWorkDayRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserWorkDayRequestDto';
import { ScheduleProxy } from 'Api/Features/Schedules/ScheduleProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { GetTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetTeamDefaultScheduleRequestDto';
import { UpdateTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/UpdateTeamDefaultScheduleRequestDto';
import { UpdateUserDefaultSchedulePreviewResponseDto } from 'Api/Features/Schedules/Dtos/UpdateUserDefaultSchedulePreviewResponseDto';

@inject(ScheduleProxy)
export class ScheduleService extends ApiService {
    constructor(private readonly scheduleProxy: ScheduleProxy) {
        super();
    }

    public async getUserDefaultSchedule(
        userId: string,
        request: GetUserDefaultScheduleRequestDto | null
    ): Promise<ScheduleDto | null> {
        const data: ScheduleDto | null = await this.scheduleProxy.getUserDefaultSchedule(
            userId,
            request
        );
        return data;
    }

    public async updateUserDefaultSchedule(
        userId: string,
        request: UpdateUserDefaultScheduleRequestDto | null
    ): Promise<void> {
        await this.scheduleProxy.updateUserDefaultSchedule(userId, request);
    }

    public async updateUserDefaultSchedulePreview(
        userId: string,
        request: UpdateUserDefaultScheduleRequestDto | null
    ): Promise<UpdateUserDefaultSchedulePreviewResponseDto | null> {
        const data: UpdateUserDefaultSchedulePreviewResponseDto | null =
            await this.scheduleProxy.updateUserDefaultSchedulePreview(userId, request);
        return data;
    }

    public async getTeamDefaultSchedule(
        teamId: string,
        request: GetTeamDefaultScheduleRequestDto | null
    ): Promise<ScheduleDto | null> {
        const data: ScheduleDto | null = await this.scheduleProxy.getTeamDefaultSchedule(
            teamId,
            request
        );
        return data;
    }

    public async updateTeamDefaultSchedule(
        teamId: string,
        request: UpdateTeamDefaultScheduleRequestDto | null
    ): Promise<void> {
        await this.scheduleProxy.updateTeamDefaultSchedule(teamId, request);
    }

    public async getUserSchedule(
        userId: string,
        request: GetUserScheduleRequestDto | null
    ): Promise<ScheduleDto | null> {
        const data: ScheduleDto | null = await this.scheduleProxy.getUserSchedule(userId, request);
        return data;
    }

    public async createUserScheduleEvent(
        userId: string,
        request: CreateUserScheduleEventRequestDto | null
    ): Promise<void> {
        await this.scheduleProxy.createUserScheduleEvent(userId, request);
    }

    public async updateUserScheduleEvent(
        userId: string,
        scheduleEventId: string,
        request: UpdateUserScheduleEventRequestDto | null
    ): Promise<void> {
        await this.scheduleProxy.updateUserScheduleEvent(userId, scheduleEventId, request);
    }

    public async updateUserScheduleTimeline(
        userId: string,
        request: UpdateUserScheduleTimelineRequestDto | null
    ): Promise<void> {
        await this.scheduleProxy.updateUserScheduleTimeline(userId, request);
    }

    public async updateUserWorkDay(
        userId: string,
        request: UpdateUserWorkDayRequestDto
    ): Promise<void> {
        await this.scheduleProxy.updateUserWorkDay(userId, request);
    }

    public async deleteUserScheduleEvent(userId: string, scheduleEventId: string): Promise<void> {
        await this.scheduleProxy.deleteUserScheduleEvent(userId, scheduleEventId);
    }

    public async getDailySchedules(
        request: GetDaySchedulesRequestDto | null
    ): Promise<[GetDaySchedulesResponseItemDto[], number]> {
        const data: GetDaySchedulesResponseDto | null = await this.scheduleProxy.getDaySchedules(
            request
        );
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async getDaySimilarSchedules(
        request: GetDaySimilarSchedulesRequestDto
    ): Promise<[GetDaySimilarSchedulesResponseItemDto[], number]> {
        const response: GetDaySimilarSchedulesResponseDto | null =
            await this.scheduleProxy.getDaySimilarSchedules(request);
        const nonNullResponse =
            response && response.items
                ? response.items.filter(
                      (item): item is GetDaySimilarSchedulesResponseItemDto => item !== null
                  )
                : [];

        return [nonNullResponse, response?.totalItemCount ?? 0];
    }

    public async getDailyWorkStats(
        request: GetDailyWorkStatsRequestDto
    ): Promise<GetDailyWorkStatsResponseDto> {
        const data: any = await this.scheduleProxy.getDailyWorkStats(request);
        return data;
    }

    public async getUserWorkStatus(
        userId: string,
        request: GetUserWorkStatusRequestDto | null
    ): Promise<GetUserWorkStatusResponseDto | null> {
        const data: GetUserWorkStatusResponseDto | null =
            await this.scheduleProxy.getUserWorkStatus(userId, request);
        return data;
    }

    public async getDayUserCounts(
        request: GetDayUserCountsRequestDto
    ): Promise<GetDayUserCountsResponseDto | null> {
        const data: GetDayUserCountsResponseDto | null = await this.scheduleProxy.getDayUserCounts(
            request
        );

        return data;
    }
}
