import React from 'react';
import './index.less';

const NUMBER_SECTION = 6;

export const officesDetailsSkeletonShape = (
    <div className="officesDetailsSkeletonShape">
        {[...Array(NUMBER_SECTION)].map((item, key) => {
            return (
                <div key={key} className="wrapper-one-section">
                    <div className="rect-top" />
                    <div className="rect-bottom" />
                </div>
            );
        })}
    </div>
);
