import { Form, FormInstance, Tabs } from 'antd';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import Content from 'Components/Content';
import Icon from 'Components/icons/Icon';
import { useFormValidation, useService, useStores, useFormNavigationBlocker } from 'Hooks';
import { observer } from 'mobx-react';
import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditProfileSchema } from 'Schemas/EditProfileSchema';
import { UserService } from 'Services/UserService';
import ProfileDetailsTab from './components/profile-tabs-content/profile-details-tab';
import WorkplacesTab from './components/profile-tabs-content/workplaces-tab';
import ContactPreferencesTab from './components/profile-tabs-content/contact-preferences-tab';
import { ContactMethodTypeDto } from 'Api/Features/General/Dtos/ContactMethodTypeDto';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import ManageAccountTab from './components/profile-tabs-content/manage-account-tab';
import ProfileNavigationTabs from './components/profile-navigation-tabs/profile-navigation-tabs';
import ConfirmationModal from 'Components/confirmation-modal/confirmation-modal';
import { WarningIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import HoursPreferencesTab from './components/profile-tabs-content/hours-preferences-tab';
import './index.less';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';

export enum ProfileTabs {
    ProfileDetails = 'ProfileDetails',
    ContactPreferences = 'ContactPreferences',
    Workplace = 'Workplaces',
    HoursPreferences = 'HoursPreferences',
    ManageAccount = 'ManageAccount',
}

interface ProfileContextInterface {
    formInstance: FormInstance<any>;
    formErrors: Map<string, string[]>;
    resetFormErrors: () => void;
    submit: (tab: ProfileTabs, imageRequest?: UpdateFileRequestDto | null) => Promise<void>;
    navigationIsBlocked: boolean;

    //contact preferences tab
    phoneNumberIsValid: boolean;
    phoneNumberInputWasFocused: boolean;
}

//context defined just above component return statement
export const ProfileContext = createContext<ProfileContextInterface | undefined>(undefined);

const Profile: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const { globalLoadingStore, userStore, toastStore, confirmationModalStore } = useStores();
    const userService = useService(UserService);
    const [form] = Form.useForm();
    const [errors, validateForm, resetErrors] = useFormValidation(EditProfileSchema, form);
    const [activeTabKey, setActiveTabKey] = useState<ProfileTabs>(ProfileTabs.ProfileDetails);

    const [setNavigationIsBlocked, navigationIsBlocked] = useFormNavigationBlocker();

    //We want that the profile tabs act as navigating away from page like the useFormNavigationBlocker hook.
    //If current form tab is edited, trying to switch tab should trigger warning modal before switching
    const [navBlockedModalState, setNavBlockedModalState] = useState({
        visible: false,
        tabOnAccept: ProfileTabs.ProfileDetails,
    });

    useEffect(() => {
        //callbacks from profile interactions can tell us what tab should be active
        if (location.search) {
            const searchParams = new URLSearchParams(location.search);
            if (
                searchParams.get('activeTab') &&
                Object.values(ProfileTabs).includes(searchParams.get('activeTab')! as ProfileTabs)
            )
                setActiveTabKey(searchParams.get('activeTab')! as ProfileTabs);
        }
    }, [location.search]);

    const onSubmit = async (
        submitTab: ProfileTabs,
        imageRequest?: UpdateFileRequestDto | null
    ): Promise<void> => {
        /* --Adding new properties in profile should also be added in the settings/users/id section to display new values-- */
        const formData = form.getFieldsValue();

        const profileDetailsTabFormData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            locationGooglePlaceId:
                formData.locationGooglePlaceId === 'deleted'
                    ? undefined
                    : formData.locationGooglePlaceId,
            timeZone: formData.timeZone,
            languageCodes:
                formData.languageCodes?.map((lang: MultiSelectCustomOption) => lang.value) ??
                userStore.userInfo?.languageCodes,

            jobTitle: formData.jobTitle,
            department: formData.department,
            image: imageRequest,
            skills: formData.skills,
            certifications: formData.certifications?.map((cert) => ({
                ...cert,
                date: moment(cert.date).format(FORMAT_YEAR_MONTH_DAY),
            })),
        };

        if (
            profileDetailsTabFormData.timeZone !== userStore.userInfo?.timeZone &&
            !(await confirmTimezoneChange())
        ) {
            return;
        }

        const contactPrerencesTabFormData = {
            contactPreferencesPhoneNumberEnabled:
                formData.contactPreferencesPhoneNumberEnabled ?? false,
            contactPreferencesPhoneNumber: formData.contactPreferencesPhoneNumber,
            contactPreferencesEmailEnabled: formData.contactPreferencesEmailEnabled ?? false,
            contactPreferencesEmail: formData.contactPreferencesEmail,
            contactPreferencesSlackEnabled: formData.contactPreferencesSlackEnabled ?? false,
            contactPreferencesSlack:
                formData.contactPreferencesSlack === '' ? null : formData.contactPreferencesSlack,
            contactPreferencesCalendlyEnabled: formData.contactPreferencesCalendlyEnabled ?? false,
            contactPreferencesCalendly:
                formData.contactPreferencesCalendly === ''
                    ? null
                    : formData.contactPreferencesCalendly,
        };

        const workplacesTabFormData = {
            defaultOfficeEnabled: formData.defaultOfficeEnabled,
            defaultOfficeId: formData.defaultOfficeId?.value,
            defaultOfficeSpaceId: formData.defaultOfficeSpaceId?.value,
            defaultFloor: formData.defaultFloor,
            defaultDesk: formData.defaultDesk,
            preferredWorkType: formData.preferredWorkType ?? userStore.userInfo?.preferredWorkType,
        };

        const hoursPreferencesTabFormData = {
            defaultScheduleType: formData.defaultScheduleType,
        };

        const validationObject = {
            //spread data from store
            ...userStore.userInfo,

            //spread data from current selected tab form values to override spread from user store
            ...(submitTab === ProfileTabs.ProfileDetails
                ? { ...profileDetailsTabFormData }
                : submitTab === ProfileTabs.ContactPreferences
                ? { ...contactPrerencesTabFormData }
                : submitTab === ProfileTabs.Workplace
                ? { ...workplacesTabFormData }
                : submitTab === ProfileTabs.HoursPreferences
                ? { ...hoursPreferencesTabFormData }
                : {}),

            //manage account tab. So far everything in that tab is self submitable so we take what is in userstore for the values here
            contactInfo: {
                email: userStore.userInfo?.contactInfo?.email,
            },
            preferredLanguageCode: userStore.userInfo?.preferredLanguageCode,

            //some values from UserDetailsDto store do not map 1:1 to UpdateUserRequestDto. map these correctly if submiting from a different tab
            ...(submitTab !== ProfileTabs.Workplace
                ? {
                      defaultOfficeId: userStore.userInfo?.defaultOffice?.id,
                      defaultOfficeSpaceId: userStore.userInfo?.defaultOfficeSpace?.id,
                  }
                : {}),
            ...(submitTab !== ProfileTabs.ProfileDetails
                ? {
                      locationGooglePlaceId: userStore.userInfo?.location?.googlePlaceId,
                  }
                : {}),

            //values that cannot change in profile forms
            badges: userStore.userInfo?.badges,
            hasSeenOnboarding: userStore.userInfo?.hasSeenOnboarding,
            teamId: userStore.userInfo?.team?.id,
        };

        const isValidFormData = await validateForm(validationObject);

        if (!isValidFormData || !profileContext.phoneNumberIsValid) return;

        const editProfileRequest: UpdateUserRequestDto = {
            ...validationObject,
            contactPreferences: {
                methods:
                    submitTab === ProfileTabs.ContactPreferences
                        ? [
                              {
                                  type: ContactMethodTypeDto.PhoneNumber,
                                  value: contactPrerencesTabFormData.contactPreferencesPhoneNumber,
                                  enabled:
                                      contactPrerencesTabFormData.contactPreferencesPhoneNumberEnabled,
                              },
                              {
                                  type: ContactMethodTypeDto.Email,
                                  value: contactPrerencesTabFormData.contactPreferencesEmail,
                                  enabled:
                                      contactPrerencesTabFormData.contactPreferencesEmailEnabled,
                              },
                              {
                                  type: ContactMethodTypeDto.Slack,
                                  value: contactPrerencesTabFormData.contactPreferencesSlack,
                                  enabled:
                                      contactPrerencesTabFormData.contactPreferencesSlackEnabled,
                              },
                              {
                                  type: ContactMethodTypeDto.Calendly,
                                  value: contactPrerencesTabFormData.contactPreferencesCalendly,
                                  enabled:
                                      contactPrerencesTabFormData.contactPreferencesCalendlyEnabled,
                              },
                          ]
                        : userStore.userInfo?.contactPreferences?.methods,
            },
        };

        try {
            globalLoadingStore.addLoading();

            await userService.updateUser(userStore.userInfo?.id ?? '', editProfileRequest);

            await userStore.setUserInfo(userStore.userInfo!.id!);

            setNavigationIsBlocked(false);
            resetErrors();
            setProfileContext((prev) => ({
                ...prev,
                formErrors: new Map(),
            }));

            toastStore.toast({
                type: 'success',
                message: t('Toast.success_message', {
                    param1: t('profile'),
                }),
            });
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const confirmTimezoneChange = async (): Promise<boolean> => {
        return await confirmationModalStore.confirm({
            icon: <WarningIcon fill={theme['warning-mid-contrast']} />,
            title: t('Profile.profile_change_timezone_warning_title'),
            message: t('Profile.profile_change_timezone_warning_message'),
            positiveText: t('confirm'),
            negativeText: t('cancel'),
        });
    };

    const [profileContext, setProfileContext] = useState<ProfileContextInterface>({
        formInstance: form,
        formErrors: errors,
        resetFormErrors: resetErrors,
        submit: onSubmit,
        navigationIsBlocked: false,

        //contact preferences tab
        phoneNumberIsValid: true,
        phoneNumberInputWasFocused: false,
    });

    useEffect(() => {
        if (errors.size > 0) {
            setProfileContext((prev) => ({
                ...prev,
                formErrors: errors,
            }));
        }
    }, [errors]);

    const handleNavigationTabClick = (activeKey: string) => {
        if (navigationIsBlocked)
            setNavBlockedModalState({ visible: true, tabOnAccept: activeKey as ProfileTabs });
        else setActiveTabKey(activeKey as ProfileTabs);
    };

    return (
        <div className="Profile">
            <ProfileContext.Provider value={profileContext}>
                <Form
                    layout="vertical"
                    form={form}
                    onFieldsChange={(changedFields) => {
                        const phoneFieldIndex = changedFields.findIndex(
                            (field) => field.name[0] === 'contactPreferencesPhoneNumber'
                        );
                        //phone input triggers on change when setting data from store. only block if user focused input
                        if (phoneFieldIndex !== -1 && profileContext.phoneNumberInputWasFocused) {
                            setNavigationIsBlocked(true);
                        } else if (changedFields.length > 0 && phoneFieldIndex === -1)
                            //normally only need this when no phone number input
                            setNavigationIsBlocked(true);
                    }}
                >
                    <ProfileNavigationTabs
                        activeKey={activeTabKey}
                        onTabClick={(activeKey) => handleNavigationTabClick(activeKey)}
                    >
                        <Tabs.TabPane
                            key={ProfileTabs.ProfileDetails}
                            tab={
                                <>
                                    <Icon iconName="PersonFillIcon" width={26} height={26} />
                                    {t('profile_details')}
                                </>
                            }
                        >
                            <Content className="layout-small-size">
                                <ProfileDetailsTab />
                            </Content>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            key={ProfileTabs.ContactPreferences}
                            tab={
                                <>
                                    <Icon iconName="PeopleFillIcon" width={26} height={26} />
                                    {t('contact_preferences')}
                                </>
                            }
                        >
                            <Content className="layout-small-size">
                                <ContactPreferencesTab />
                            </Content>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            key={ProfileTabs.Workplace}
                            tab={
                                <>
                                    <Icon
                                        iconName="HouseBuildingIcon"
                                        fill="currentColor"
                                        width={26}
                                        height={26}
                                    />
                                    {t('workplaces')}
                                </>
                            }
                        >
                            <Content className="layout-small-size">
                                <WorkplacesTab />
                            </Content>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            key={ProfileTabs.HoursPreferences}
                            tab={
                                <>
                                    <Icon iconName="ClockFilledIcon" width={26} height={26} />
                                    {t('hours_preferences')}
                                </>
                            }
                        >
                            <Content>
                                <HoursPreferencesTab />
                            </Content>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            key={ProfileTabs.ManageAccount}
                            tab={
                                <>
                                    <Icon iconName="GrowingChartIcon" width={26} height={26} />
                                    {t('manage_account')}
                                </>
                            }
                        >
                            <Content className="layout-medium-size">
                                <ManageAccountTab />
                            </Content>
                        </Tabs.TabPane>
                    </ProfileNavigationTabs>
                </Form>
            </ProfileContext.Provider>

            {navBlockedModalState.visible && (
                <ConfirmationModal
                    id="navigation-blocker-confirmation"
                    icon={<WarningIcon fill={theme['warning-mid-contrast']} />}
                    title={t('FormNavBlocker.form_nav_title')}
                    message={t('FormNavBlocker.form_nav_message')}
                    positive={{
                        text: t('continue'),
                        action: () => {
                            setActiveTabKey(navBlockedModalState.tabOnAccept);
                            setNavBlockedModalState({
                                visible: false,
                                tabOnAccept: navBlockedModalState.tabOnAccept,
                            });
                            setNavigationIsBlocked(false);
                            //this will reset the fields with store values with all the useeffects
                            userStore.reInitUserInfoObject();
                        },
                    }}
                    negative={{
                        text: t('cancel'),
                        action: () => {
                            setNavBlockedModalState({
                                visible: false,
                                tabOnAccept: navBlockedModalState.tabOnAccept,
                            });
                        },
                    }}
                />
            )}
        </div>
    );
});

export default Profile;
