import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { ScheduleDayDto } from 'Api/Features/Schedules/Dtos/ScheduleDayDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import ScrollInfinite from 'Components/scroll-infinite';
import { useService, useStores } from 'Hooks';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HappeningService } from 'Services/HappeningService';
import { ScheduleService } from 'Services/ScheduleService';
import {
    createMomentFromUtcWithoutChangingTime,
    createTimezonedMomentFromDateAndTime,
} from 'Utils/TimeUtils';
import { wrapperCardDayHorizontal } from '../skeleton-shapes';
import './dashboard-mobile-next-days.less';
import WrapperCardDayVertical from './wrapper-card-day-vertical';
import { WorkingCoworkersModalMode } from 'Components/coworkers-at-office-modal/working-coworkers-modal';

interface DashboardMobileNextDaysProps {
    selectedOfficeFilterName: string;
    setWorkingCoworkerModalState: Dispatch<
        SetStateAction<{
            visible: boolean;
            startingDate?: string | undefined;
            workType?: WorkTypeDto | undefined;
            mode: WorkingCoworkersModalMode;
        }>
    >;
    selectedOfficeFilterId?: string;
}

const DashboardMobileNextDays: React.FunctionComponent<DashboardMobileNextDaysProps> = ({
    selectedOfficeFilterName,
    setWorkingCoworkerModalState,
    selectedOfficeFilterId,
}) => {
    const scheduleService = useService(ScheduleService);
    const happeningService = useService(HappeningService);
    const { userStore, toastStore } = useStores();
    const [page, setPage] = useState(0);
    const [days, setDays] = useState<ScheduleDayDto[]>([]);
    const [events, setEvents] = useState<ScheduleEventDto[]>([]);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [daysWithTitleSeperators, setDaysWithTitleSeperators] = useState<
        Map<string /*date*/, string /*label*/> | undefined
    >();
    const [cardToOpen, setCardToOpen] = useState('');
    const [happenings, setHappenings] = useState<HappeningDto[]>([]);

    const nextDaysDayOffset = useRef<number>(0);

    const { t } = useTranslation();
    const todayMoment = moment();

    const NEXT_DAYS_DISPLAY = 7;
    const NEXT_DAYS_TO_LOAD = 7;
    const NEXT_DAYS_MAX = NEXT_DAYS_DISPLAY * 4;

    const handleOpenClick = (date) => {
        setCardToOpen((prev) => (prev === date ? '' : date));
    };

    const fetchHappeningsForNextDays = useCallback(async () => {
        try {
            const reponse = await happeningService.getHappenings({
                minEndTime: createTimezonedMomentFromDateAndTime(
                    moment().format(FORMAT_YEAR_MONTH_DAY),
                    '00:00',
                    userStore.userInfo?.timeZone ?? ''
                ).format(),
            });

            if (reponse && reponse[0]) setHappenings((prev) => [...reponse[0], ...prev]);
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        }
    }, [page]);

    const getDateTitleSeperators = (days: ScheduleDayDto[]) => {
        //depending on work restrictions, some days may be ommited from api results so find the first date that is not this week
        const endCurrentWeek = moment().endOf('week');

        const firstDateCurrentWeek = days.find((day) =>
            createMomentFromUtcWithoutChangingTime(
                day.date ?? '',
                userStore.userInfo?.timeZone ?? ''
            ).isSameOrBefore(endCurrentWeek)
        )?.date;

        const startNextWeek = moment().add(1, 'week').startOf('week');

        const firstDateUpcoming = days.find((day) =>
            createMomentFromUtcWithoutChangingTime(
                day.date ?? '',
                userStore.userInfo?.timeZone ?? ''
            ).isSameOrAfter(startNextWeek)
        )?.date;

        const dateLabelMap = new Map<string, string>();

        if (firstDateCurrentWeek) {
            dateLabelMap.set(firstDateCurrentWeek, t('this_week'));
        }

        if (firstDateUpcoming) {
            dateLabelMap.set(firstDateUpcoming, t('upcoming_weeks'));
        }

        setDaysWithTitleSeperators(dateLabelMap);
    };

    const fetchNextDays = useCallback(
        async (loadedDays?: number) => {
            try {
                setItemsLoading(true);

                const scheduleRequest: GetUserScheduleRequestDto = {
                    startDate: todayMoment.format(FORMAT_YEAR_MONTH_DAY),
                    nextWorkDays: NEXT_DAYS_TO_LOAD,
                    nextWorkDaysOffset: loadedDays,
                    timeZone: userStore?.userInfo?.timeZone,
                };

                const response = await scheduleService.getUserSchedule(
                    userStore?.userInfo?.id || '',
                    scheduleRequest
                );

                if (!response) return;

                setEvents((prev: any) => {
                    return [...prev, ...(response?.events || [])];
                });

                if (!daysWithTitleSeperators)
                    getDateTitleSeperators(response.days as ScheduleDayDto[]);

                setDays((prev: any) => {
                    return [...prev, ...(response.days || [])];
                });
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                setItemsLoading(false);
            }
        },
        [page, userStore?.userInfo?.timeZone, userStore?.userInfo?.id]
    );

    const handlePagination = () => {
        if (nextDaysDayOffset.current < NEXT_DAYS_MAX) {
            nextDaysDayOffset.current = nextDaysDayOffset.current + NEXT_DAYS_TO_LOAD;
            setPage((prevPage) => prevPage + 1);
        }
    };

    const changeAllOfficeDay = (
        date: string,
        office?: { name?: string; id?: string },
        officeSpace?: { name?: string; id?: string }
    ) => {
        setEvents((prev: ScheduleEventDto[]) => {
            return prev.map((item) => {
                if (moment.utc(date).isSame(item?.startTime, 'day'))
                    return {
                        ...item,
                        office: {
                            ...office,
                        },
                        officeSpace: {
                            ...officeSpace,
                        },
                    };

                return item;
            });
        });
    };

    const changeTypeOfDay = async (date: string, workType: WorkTypeDto) => {
        setEvents((prev: ScheduleEventDto[]) => {
            return prev.map((item) => {
                if (moment.utc(date).isSame(item?.startTime, 'day'))
                    return {
                        ...item,
                        workType: workType,
                        workplace: {
                            ...item.office,
                            id: '',
                        },
                    };

                return item;
            });
        });

        setDays((prev: ScheduleDayDto[]) => {
            return prev.map((item) => {
                if (moment.utc(date).isSame(item.date, 'day')) {
                    return {
                        ...item,
                        workTypes: [workType],
                    };
                }

                return item;
            });
        });
    };

    useEffect(() => {
        if (userStore?.userInfo?.timeZone) fetchNextDays(nextDaysDayOffset.current);
    }, [userStore?.userInfo?.timeZone, nextDaysDayOffset.current]);

    useEffect(() => {
        fetchHappeningsForNextDays();
    }, [userStore?.userInfo?.timeZone]);

    return (
        <div className="DashboardMobileNextDays">
            <ScrollInfinite
                items={days}
                RenderItem={({ item }: { item: ScheduleDayDto }) => {
                    return (
                        <>
                            {daysWithTitleSeperators?.has(item.date ?? '') ? (
                                <div className="text-headline label-scroll-week">
                                    {daysWithTitleSeperators.get(item.date ?? '')}
                                </div>
                            ) : undefined}

                            <WrapperCardDayVertical
                                changeTypeOfDay={changeTypeOfDay}
                                changeAllDayOffice={changeAllOfficeDay}
                                selectedOfficeFilterName={selectedOfficeFilterName}
                                setWorkingCoworkerModalState={setWorkingCoworkerModalState}
                                cardToOpen={cardToOpen}
                                handleOpenClick={handleOpenClick}
                                selectedOfficeFilterId={selectedOfficeFilterId}
                                day={{
                                    ...item,
                                    hasHappening: happenings.find((el) =>
                                        moment
                                            .utc(item.date)
                                            .isBetween(
                                                moment(el.startTime).format(FORMAT_YEAR_MONTH_DAY),
                                                moment(el.endTime).format(FORMAT_YEAR_MONTH_DAY),
                                                'day',
                                                '[]'
                                            )
                                    )
                                        ? true
                                        : false,
                                    events: events.filter((event) =>
                                        moment.utc(item.date).isSame(event?.startTime, 'day')
                                    ),
                                }}
                            />
                        </>
                    );
                }}
                RenderItemSkeleton={() => wrapperCardDayHorizontal}
                handlePagination={handlePagination}
                paginationOptions={{
                    pageSize: NEXT_DAYS_DISPLAY,
                    isLoading: itemsLoading,
                }}
                numberSkeletonLoading={NEXT_DAYS_DISPLAY}
            />
        </div>
    );
};

export default DashboardMobileNextDays;
