import Button from 'Components/button';
import Modal, { ModalHeaderColor } from 'Components/modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpaceViewerModalContent, {
    SpaceViewerScheduleType,
} from './components/space-viewer-modal-content';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { EMPTY_GUID } from 'Models/Constants';
import SubmitButton from 'Components/submit-button/submit-button';

interface SpaceViewerModalProps {
    visible: boolean;
    onComplete: (success: boolean, space?: SingleSelectCustomOption) => void;
    selectedOffice: SingleSelectCustomOption;
    selectedSpaceProp?: SingleSelectCustomOption;
    timeRange: {
        startTime: string;
        endTime: string;
    };
}

const SpaceViewerModal: React.FC<SpaceViewerModalProps> = ({
    visible,
    onComplete,
    selectedOffice,
    selectedSpaceProp,
    timeRange,
}) => {
    const { t } = useTranslation();

    const [selectedSpace, setSelectedSpace] = useState<SingleSelectCustomOption | undefined>(
        selectedSpaceProp ?? undefined
    );

    const submit = async (): Promise<void> => {
        dismiss(true, selectedSpace ? selectedSpace : { value: EMPTY_GUID, label: '' }); //must put empty_guid thats how api removes space in the dashboard call
    };

    const dismiss = (success = false, space: SingleSelectCustomOption | undefined): void => {
        onComplete(success, space);
    };

    return (
        <Modal
            className="SpaceViewerModal"
            visible={visible}
            onCancel={() => dismiss(false, undefined)}
            headerText={selectedOffice.label + ' - ' + t('choose_a_space')}
            width={640}
            headerColor={ModalHeaderColor.white}
            noPadding
            footer={
                <>
                    <Button
                        text={t('cancel')}
                        type="tertiary"
                        onClick={() => dismiss(false, undefined)}
                    />

                    <SubmitButton text={t('confirm')} type="primary" onClick={() => submit()} />
                </>
            }
        >
            <SpaceViewerModalContent
                officeId={selectedOffice.value}
                timeRange={timeRange}
                setSelectedSpace={setSelectedSpace}
                selectedSpace={selectedSpace}
                scheduleType={SpaceViewerScheduleType.schedule}
            />
        </Modal>
    );
};

export default SpaceViewerModal;
