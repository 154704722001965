import { Input } from 'antd';
import { GetDaySchedulesRequestDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesRequestDto';
import { GetDaySchedulesResponseItemDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesResponseItemDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserTeamFilterModeDto } from 'Api/Features/Users/Dtos/UserTeamFilterModeDto';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    MagnifyingGlassIcon,
    TrayArrowDownIcon,
} from 'Components/icons';
import { useAsyncSingleSelectProps, useService, useStores, useDebounce } from 'Hooks';
import { observer } from 'mobx-react-lite';
import {
    FORMAT_DAY_WRITTEN,
    FORMAT_MONTH_WRITTEN_DATE,
    PAGE_SIZE_INFINITE_LOADING,
    FORMAT_YEAR_MONTH_DAY,
    DEBOUNCE_DELAY_400,
} from 'Models/Constants';
import moment from 'moment';
import { default as React, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleService } from 'Services/ScheduleService';
import { theme } from 'Style/theme';

import Modal from 'Components/modal';
import './working-coworkers-modal.less';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeService } from 'Services/OfficeService';
import Skeleton from 'Components/skeleton';
import { HappeningService } from 'Services/HappeningService';
import { GetHappeningsRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningsRequestDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { createTimezonedMomentFromDateAndTime } from 'Utils/TimeUtils';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import HorizontalRadio from 'Components/horizontal-radio';
import HappeningsCollapse from './happenings/happenings-collapse';
import ScrollInfinite from 'Components/scroll-infinite';
import CoworkerWorkingLine from './coworkers/coworker-working-line';
import { coworkersModalItemShapes } from './skeleton-shapes';

interface WorkingCoworkersModalProps {
    visible: boolean;
    onComplete?: (copyEvent?: ScheduleEventDto) => void;
    startingDate: string;
    teamId: string;
    workType: WorkTypeDto;
    mode: WorkingCoworkersModalMode;
    selectedOfficeFilter?: SingleSelectCustomOption;
    fullScreen?: boolean;
}

export enum WorkingCoworkersModalMode {
    CurrentlyWorking = 'CurrentlyWorking',
    CoworkerList = 'CoworkerList',
}

enum modeChangeDate {
    advance,
    back,
}

enum CoworkersAtOfficeModalTabs {
    MY_TEAM = 'MY_TEAM',
    ALL_COWORKERS = 'ALL_COWORKERS',
    FAVORITES = 'FAVORITES',
}

const WorkingCoworkersModal: React.FunctionComponent<WorkingCoworkersModalProps> = observer(
    ({
        visible,
        onComplete,
        startingDate,
        teamId,
        workType,
        mode,
        selectedOfficeFilter: selectedOfficeFilterProp,
        fullScreen,
    }) => {
        const { t } = useTranslation();
        const { toastStore, userStore, languageStore } = useStores();
        const scheduleService = useService(ScheduleService);
        const officeService = useService(OfficeService);
        const happeningService = useService(HappeningService);

        const [selectDate, setSelectDate] = useState(startingDate);

        // happenings
        const [happenings, setHappenings] = useState<HappeningDto[]>([]);

        const [coworkers, setCoworkers] = useState<GetDaySchedulesResponseItemDto[]>([]);
        const [coworkerscurrentPage, setcoworkersCurrentPage] = useState(0);
        const [coworkersTotalItemCount, setCoworkersTotalItemCount] = useState(0);
        const [searchTerm, setSearchTerm] = useState<string>();
        const debounceSearch = useDebounce(searchTerm, DEBOUNCE_DELAY_400);

        const [isLoading, setIsLoading] = useState({
            happenings: false,
            coworkers: false,
        });

        const [currentTabCoworkers, setCurrentTabCoworkers] = useState(
            userStore.userInfo?.team
                ? CoworkersAtOfficeModalTabs.MY_TEAM
                : CoworkersAtOfficeModalTabs.ALL_COWORKERS
        );

        const [tabOptions, setTabOptions] = useState([
            {
                label: t('all_coworkers'),
                value: CoworkersAtOfficeModalTabs.ALL_COWORKERS,
                countIsLoading: false,
            },
            {
                label: t('favorites'),
                value: CoworkersAtOfficeModalTabs.FAVORITES,
                countIsLoading: false,
            },
        ]);

        // offices
        const [selectedOfficeFilter, setSelectedOfficeFilter] = useState<string>(
            selectedOfficeFilterProp?.value ?? 'all'
        );
        const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficesRequestDto) =>
                    await officeService.getOffices(request),
            },
            entityToSingleSelectCustomOption: (office: OfficeDto) =>
                ({ label: office.name, value: office.id } as SingleSelectCustomOption),
            extraMandatoryOptions: {
                unshift: [
                    {
                        label: t('all_offices'),
                        value: 'all',
                    },
                ],
            },
            defaultSelectedOption: selectedOfficeFilterProp ?? {
                label: t('all_offices'),
                value: 'all',
            },
        });

        let headerText: string;
        let fullScreenHeaderText: string;
        switch (mode) {
            case WorkingCoworkersModalMode.CoworkerList:
                headerText = t('Profile.coworkers_list');
                fullScreenHeaderText = t('Profile.coworkers_list');
                break;
            case WorkingCoworkersModalMode.CurrentlyWorking:
                headerText =
                    workType === WorkTypeDto.Office
                        ? t('Profile.coworkers_list_currently_office')
                        : t('Profile.coworkers_list_currently_remote');
                fullScreenHeaderText =
                    workType === WorkTypeDto.Office ? t('at_office') : t('in_remote');
                break;
            default:
                headerText = t('Profile.coworkers_list');
                fullScreenHeaderText = t('Profile.coworkers_list');
                break;
        }

        const subHeaderComponent = (
            <div className="sub-header center">
                {mode === WorkingCoworkersModalMode.CoworkerList && (
                    <ChevronLeftIcon
                        height={22}
                        width={22}
                        onClick={() => onChangeDate(modeChangeDate.back)}
                    />
                )}

                <div className="title-day">
                    <div className="header-container">
                        <span className="text-headline-bold capitalize">
                            {moment(selectDate).format(FORMAT_DAY_WRITTEN)}
                        </span>
                        <div className="dot" />
                        <span className="text-callout date">
                            {moment(selectDate).format(
                                FORMAT_MONTH_WRITTEN_DATE[languageStore.currentLanguage]
                            )}
                        </span>
                    </div>
                    <div className="text-caption-2 text-mid-contrast inyourtimezone">
                        ({t('in_your_timezone')})
                    </div>
                </div>

                {mode === WorkingCoworkersModalMode.CoworkerList && (
                    <ChevronRightIcon
                        height={22}
                        width={22}
                        onClick={() => onChangeDate(modeChangeDate.advance)}
                    />
                )}
            </div>
        );

        const dismiss = (): void => {
            onComplete && onComplete();
        };

        const onChangeFavorites = (
            state: 'add' | 'remove',
            coworkerId: string,
            relationId: string
        ) => {
            setCoworkers((prev) => {
                if (prev) {
                    return prev.map((el) => {
                        if (el.user?.id === coworkerId) {
                            return {
                                ...el,
                                user: {
                                    ...el.user,
                                    favoriteInfo: {
                                        ...el.user.favoriteInfo,
                                        id: state === 'add' ? relationId : '',
                                    },
                                },
                            };
                        }

                        return el;
                    });
                } else {
                    return [];
                }
            });
        };

        const fetchCoworkers = useCallback(
            async (
                currentPage: number,
                officeId: string,
                tab: CoworkersAtOfficeModalTabs,
                searchTerm?: string
            ) => {
                try {
                    setIsLoading((prev) => ({
                        ...prev,
                        coworkers: true,
                    }));

                    const request: GetDaySchedulesRequestDto = {
                        date: moment(selectDate).format(FORMAT_YEAR_MONTH_DAY),
                        timeZone: userStore.userInfo?.timeZone,
                        searchTerm: searchTerm,
                        pageSize: PAGE_SIZE_INFINITE_LOADING,
                        excludedUserIds: userStore.userInfo?.id ? [userStore.userInfo?.id] : [],

                        page: currentPage,
                        teamIds:
                            tab === CoworkersAtOfficeModalTabs.MY_TEAM && teamId
                                ? [teamId]
                                : undefined,
                        favoritesForUserId: userStore.userInfo?.id,
                        teamFilterMode:
                            tab === CoworkersAtOfficeModalTabs.MY_TEAM
                                ? UserTeamFilterModeDto.Members
                                : undefined,
                        favoritesOnly: tab === CoworkersAtOfficeModalTabs.FAVORITES,

                        dayWorkTypes:
                            mode === WorkingCoworkersModalMode.CoworkerList
                                ? [workType]
                                : undefined,
                        currentWorkTypes:
                            mode === WorkingCoworkersModalMode.CurrentlyWorking
                                ? [workType]
                                : undefined,
                        dayOfficeIds: officeId !== 'all' ? [officeId] : undefined,
                    };

                    const [response, totalItemCount] = await scheduleService.getDailySchedules(
                        request
                    );

                    setCoworkersTotalItemCount(totalItemCount);

                    setCoworkers((prev) =>
                        currentPage > 0 ? [...prev, ...response] : [...response]
                    );
                } catch (e: any) {
                    if (!e.treated) {
                        toastStore.genericError();
                    }
                } finally {
                    setIsLoading((prev) => ({
                        ...prev,
                        coworkers: false,
                    }));
                }
            },
            [userStore.userInfo?.id, selectDate]
        );

        useEffect(() => {
            if (userStore.userInfo?.id)
                fetchCoworkers(
                    coworkerscurrentPage,
                    selectedOfficeFilter,
                    currentTabCoworkers,
                    debounceSearch
                );
        }, [
            coworkerscurrentPage,
            debounceSearch,
            userStore.userInfo?.id,
            selectedOfficeFilter,
            currentTabCoworkers,
            selectDate,
        ]);

        useEffect(() => {
            resetCoworkerSearchResults();
        }, [debounceSearch]);

        const resetCoworkerSearchResults = () => {
            setCoworkers([]);
            setcoworkersCurrentPage(0);
            setCoworkersTotalItemCount(0);
        };

        const handleCoworkersScrollToBottom = () => {
            const maxIsAlreadyLoaded =
                coworkersTotalItemCount > 0 ? coworkers.length >= coworkersTotalItemCount : false;
            if (maxIsAlreadyLoaded) return;

            setcoworkersCurrentPage((prev) => prev + 1);
        };

        const handleCopyEventClick = (event: ScheduleEventDto) => {
            onComplete && onComplete(event);
        };

        const onChangeTab = (value: any) => {
            resetCoworkerSearchResults();
            setCurrentTabCoworkers(value);
        };

        const onChangeDate = (mode: modeChangeDate) => {
            resetCoworkerSearchResults();
            if (mode == modeChangeDate.advance) {
                const newDate = moment(selectDate).add(1, 'days').format(FORMAT_YEAR_MONTH_DAY);
                setSelectDate(newDate);
            } else if (
                mode == modeChangeDate.back &&
                moment
                    .tz(selectDate, userStore.userInfo?.timeZone ?? '')
                    .isAfter(moment.tz(userStore.userInfo?.timeZone ?? '').startOf('week'))
            ) {
                const newDate = moment(selectDate).add(-1, 'days').format(FORMAT_YEAR_MONTH_DAY);
                setSelectDate(newDate);
            }
        };

        const fetchHappenings = async () => {
            try {
                setIsLoading((prev) => {
                    return { ...prev, happenings: true };
                });

                const request: GetHappeningsRequestDto = {
                    officeIds: undefined, // https://appcom.atlassian.net/browse/FLEXY-3373?focusedCommentId=51000
                    searchTerm: undefined /*filterOptions.name ? filterOptions.name : undefined,*/,
                    maxStartTime: createTimezonedMomentFromDateAndTime(
                        moment(selectDate).format(FORMAT_YEAR_MONTH_DAY),
                        '23:59',
                        userStore.userInfo?.timeZone ?? ''
                    ).format(),
                    minEndTime: createTimezonedMomentFromDateAndTime(
                        moment(selectDate).format(FORMAT_YEAR_MONTH_DAY),
                        '00:00',
                        userStore.userInfo?.timeZone ?? ''
                    ).format(),
                    favoritesForUserId: userStore.userInfo?.id,
                };
                const [items] = await happeningService.getHappenings(request);
                setHappenings(items);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                setIsLoading((prev) => {
                    return { ...prev, happenings: false };
                });
            }
        };

        useEffect(() => {
            if (userStore.userInfo?.id && selectDate) fetchHappenings();
        }, [selectDate, userStore.userInfo?.id]);

        useEffect(() => {
            if (userStore.userInfo?.team) {
                setTabOptions([
                    {
                        label: t('my_team'),
                        value: CoworkersAtOfficeModalTabs.MY_TEAM,
                        countIsLoading: false,
                    },
                    {
                        label: t('all_coworkers'),
                        value: CoworkersAtOfficeModalTabs.ALL_COWORKERS,
                        countIsLoading: false,
                    },
                    {
                        label: t('favorites'),
                        value: CoworkersAtOfficeModalTabs.FAVORITES,
                        countIsLoading: false,
                    },
                ]);
            }
        }, [userStore.userInfo?.team]);

        return (
            <Modal
                className="WorkingCoworkersModal"
                visible={visible}
                onCancel={dismiss}
                headerText={headerText}
                fullScreenHeaderText={fullScreenHeaderText}
                oustideOverflow={
                    !fullScreen
                        ? {
                              element: subHeaderComponent,
                              height: 85,
                          }
                        : undefined
                }
                mobileFullScreen={fullScreen}
                fixedHeight
            >
                {fullScreen && subHeaderComponent}

                <div className="center">
                    <div className="header-btn">
                        {(mode == WorkingCoworkersModalMode.CoworkerList ||
                            workType !== WorkTypeDto.Remote) && (
                            <AsyncSingleSelect
                                {...officeSelectProps}
                                selected={selectedOfficeFilter}
                                onChange={(option?: SingleSelectCustomOption) => {
                                    if (option?.value) {
                                        resetCoworkerSearchResults();
                                        setSelectedOfficeFilter(option?.value);
                                    }
                                }}
                                placeholder={t('SelectCustom.default_select_placeholder')}
                            />
                        )}

                        <Input
                            className="search search-bar"
                            prefix={
                                <MagnifyingGlassIcon
                                    width={22}
                                    height={22}
                                    fill={theme['primary-mid-contrast']}
                                />
                            }
                            onChange={(value) => {
                                setSearchTerm(value.target.value);
                            }}
                            placeholder={t('search')}
                            name="name"
                        />
                    </div>

                    <Skeleton
                        isLoaded={!isLoading.happenings}
                        placeholder={coworkersModalItemShapes}
                    >
                        {happenings?.length == 0 ? (
                            <div />
                        ) : (
                            <HappeningsCollapse
                                happenings={happenings}
                                hideViewButton={fullScreen}
                                hideImages={fullScreen}
                                hideViewAll={!fullScreen}
                            />
                        )}
                    </Skeleton>

                    <div className="text-body-bold mt-20 mb-20">{t('coworkers')}</div>

                    <HorizontalRadio
                        name="#"
                        defaultValue={currentTabCoworkers}
                        options={tabOptions}
                        //@ts-ignore
                        onChange={onChangeTab}
                        showBorderBottom={true}
                    />

                    <div className="mt-20" />

                    <ScrollInfinite
                        items={coworkers}
                        RenderItem={({ item }: { item: GetDaySchedulesResponseItemDto }) => (
                            <CoworkerWorkingLine
                                coworker={item}
                                onChangeFavorites={onChangeFavorites}
                                onCopyEventClick={(event) => handleCopyEventClick(event)}
                                simpleMode={fullScreen}
                                date={selectDate}
                                key={item.user?.id ?? ''}
                            />
                        )}
                        RenderItemSkeleton={() => (
                            <Skeleton isLoaded={false} placeholder={coworkersModalItemShapes} />
                        )}
                        handlePagination={handleCoworkersScrollToBottom}
                        paginationOptions={{
                            pageSize: PAGE_SIZE_INFINITE_LOADING,
                            isLoading: isLoading.coworkers,
                        }}
                    />

                    {!isLoading.happenings &&
                        !isLoading.coworkers &&
                        happenings.length === 0 &&
                        coworkers?.length === 0 && (
                            <div className="workplace-container-empty">
                                <TrayArrowDownIcon
                                    width={48}
                                    height={48}
                                    fill={theme['layout-mid-contrast']}
                                />
                                <div className="text-body text-disabled mt-10">
                                    {t('Table.table_empty')}
                                </div>
                            </div>
                        )}
                </div>
            </Modal>
        );
    }
);

export default WorkingCoworkersModal;
