import React from 'react';
import './index.less';

export const coworkersModalOfficeSelectShapes = (
    <div className="coworkersModalOfficeSelectShapes">
        <div className="rect-1" />
    </div>
);

export const coworkersModalItemShapes = (
    <div className="coworkersModalItemsShapes">
        <div className="wrapper-1">
            <div className="circle-1" />
            <div className="rect-2" />
            <div className="circle-1" />
            <div className="rect-3" />
        </div>
    </div>
);
